<template>
  <md-field id="card">
    <md-steps
        :steps="steps"
        :current="order.order_status | orderStep"
    >
    </md-steps>
    <md-detail-item title="维修单号" :content="'No.'+order.id" bold/>

    <md-detail-item title="创建时间" :content="order.create_time"/>
    <md-detail-item title="状态" :content="order.order_status"/>
    <md-detail-item title="订单详情">
      <span @click="$router.push('/orderdetail/'+order.id)" style="color:#5878B4;text-decoration:none;">查看详情</span>
    </md-detail-item>
  </md-field>
</template>

<script>
import {Field, DetailItem, Steps} from 'mand-mobile'

export default {
  name: "OrderCard",
  props: ['order'],
  filters:{
    orderStep(status){
        switch (status){
          case '待接单':
            return 0
          case '已取消':
            return 2
          case '完成维修':
            return 2
          case '处理中':
            return 1
          case '已评价':
            return 3
          default:
            return null
        }
    }
  },
  data() {
    return {
      steps: [
        {
          name: '待接单',
        },
        {
          name: '处理中',
        },
        {
          name: '维修结束',
        },
        {
          name: '评价',
        },
      ],
    }
  },
  components: {
    [Field.name]: Field,
    [DetailItem.name]: DetailItem,
    [Steps.name]: Steps,
  },
}
</script>

<style scoped>
#card {
  border: 0px solid #e0e0e0 !important;
  background-color: white;
  box-shadow: 0 10px 20px 0 rgb(0 0 0 / 5%);
  border: 0;
  margin: 40px 0px;
}

/deep/ .md-steps.md-steps-horizontal {
  padding: 4px 20px 80px 20px
}
</style>
