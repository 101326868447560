<template>
    <div class="content">
      <md-button type="primary" @click="go('https://ehall.sziit.edu.cn/publicapp/sys/cjcxapp/mobile/gradesQuery/index.html')">成绩查询入口</md-button>
      <md-button type="default" @click="go('https://ehall.sziit.edu.cn/publicapp/sys/mykbxt/index.do?t_s=1645977719112&amp_sec_version_=1&gid_=MkRkbE9POTF3OGRvOCtscVhpR2sxV1hrSU9qaUY5VllESHJYMEN6MEc1VExNUXZKcjJkcUtFRUszSUpmNUxtbkdnRmsyZUQyZy9TbENoWTJwamk1N1E9PQ&EMAP_LANG=zh&THEME=cherry#/curriculum')">课表查询入口</md-button>

    </div>
</template>

<script>
    export default {
        name: "Score2",
      data(){
          return {
            scoreUrl : ''
          }
      },
      methods:{
          go(url){
            // window.location.href = url
            // window.location.assign(url)
            var referLink = document.createElement('a');
            referLink.rel = 'noreferrer';
            referLink.href = url;
            document.body.appendChild(referLink);
            referLink.click();
          }
      }



    }
</script>

<style lang="stylus" scoped>

</style>
