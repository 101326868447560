<template>
  <!--  电脑维修预约列表-->
  <div class="content">
    <OrderCard v-for="(item,key) in orderList" :key="key" :order="item"></OrderCard>
  </div>
</template>

<script>
import OrderCard from "@/components/OrderList/OrderCard";

export default {
  name: "OrderList",
  components: {
    OrderCard
  },
  mounted() {
    console.log('OrderList')
    this.getOrderList()
  },
  data() {
    return {
      // 订单列表
      orderList: [

      ]
    }
  },
  methods: {
    getOrderList() {
      this.$API.fix.orderList().then(res => {
        console.log(res)
        this.orderList = res.data
      })
    },
  }
}
</script>

<style scoped>

</style>
